// TODO
export const getAnnouncementLink = (lang: string | undefined) => {
  switch (lang) {
    case 'en':
      return 'https://blog.polymerize.io/posts/polymerize-is-now-in-japan'
    case 'ja':
      return 'https://blog.polymerize.jp/posts/polymerize-is-now-in-japan'
    case 'zh':
      return 'https://blog.polymerize.tech/posts/polymerize-is-now-in-japan'

    default:
      return 'https://blog.polymerize.io/posts/polymerize-is-now-in-japan'
  }
}

export const getSoc2Link = (lang: string | undefined) => {
  switch (lang) {
    case 'en':
      return 'https://blog.polymerize.io/posts/polymerize-is-now-soc-2-type-ii-compliant'
    case 'ja':
      return 'https://blog.polymerize.jp/posts/polymerize%E3%81%8Csoc-2%E3%82%BF%E3%82%A4%E3%83%97ii%E3%81%AE%E8%AA%8D%E8%A8%BC%E3%82%92%E5%8F%96%E5%BE%97/'
    case 'zh':
      return 'https://blog.polymerize.tech/posts/polymerize-is-now-soc-2-type-ii-compliant'

    default:
      return 'https://blog.polymerize.io/posts/polymerize-is-now-soc-2-type-ii-compliant'
  }
}

export const getMaterialInformaticsLink = (lang: string | undefined) => {
  switch (lang) {
    case 'en':
      return 'https://blog.polymerize.io/posts/material-informatics'
    case 'ja':
      return 'https://blog.polymerize.jp/posts/%E3%83%9E%E3%83%86%E3%83%AA%E3%82%A2%E3%83%AB%E3%82%A4%E3%83%B3%E3%83%95%E3%82%A9%E3%83%9E%E3%83%86%E3%82%A3%E3%82%AF%E3%82%B9/'
    case 'zh':
      return 'https://blog.polymerize.tech/posts/material-informatics'

    default:
      return 'https://blog.polymerize.io/posts/material-informatics'
  }
}

export const getTLD = (lang: string | undefined) => {
  switch (lang) {
    case 'en':
      return 'io'
    case 'ja':
      return 'jp'
    case 'zh':
      return 'tech'

    default:
      return 'io'
  }
}

export const getDataManagementLink = (lang: string | undefined) => {
  switch (lang) {
    case 'en':
      return 'https://blog.polymerize.io/category/data_management'
    case 'ja':
      return 'https://blog.polymerize.jp/category/%E3%83%87%E3%83%BC%E3%82%BF%E7%AE%A1%E7%90%86/'
    case 'zh':
      return 'https://blog.polymerize.tech/category/data_management'

    default:
      return 'https://blog.polymerize.io/category/data_management'
  }
}

export const getIndustryNewsLink = (lang: string | undefined) => {
  switch (lang) {
    case 'en':
      return 'https://blog.polymerize.io/category/industry_news'
    case 'ja':
      return 'https://blog.polymerize.jp/category/%E6%A5%AD%E7%95%8C%E3%83%8B%E3%83%A5%E3%83%BC%E3%82%B9/'
    case 'zh':
      return 'https://blog.polymerize.tech/category/industry_news'

    default:
      return 'https://blog.polymerize.io/category/industry_news'
  }
}


export const getSiteUrl = (lang: string | undefined) => {
  switch (lang) {
    case 'en':
      return 'https://polymerize.io/'
    case 'ja':
      return 'https://polymerize.jp/'
    case 'zh':
      return 'https://polymerize.tech/'
    case "kr":
      return 'https://polymerize.co.kr/'

    default:
      return 'https://polymerize.io/'
  }
}

export const getBlogLogo = (lang: string | undefined) => {
  switch (lang) {
    case 'en':
      return 'https://blog.polymerize.io/images/blog-logo.jpg'
    case 'ja':
      return 'https://blog.polymerize.jp/images/blog-logo.jpg'
    case 'zh':
      return 'https://blog.polymerize.tech/images/blog-logo.jpg'

    default:
      return 'https://blog.polymerize.io/images/blog-logo.jpg'
  }
}

export const getCookies = (lang: string | undefined) => {
  switch (lang) {
    case 'en':
      return '//cdn.cookie-script.com/s/e0e97bd8beffbdaf2b89c6e200a686ef.js'
    case 'ja':
      return '//cdn.cookie-script.com/s/b664e77dd117ff641a26fd5a7038a89e.js'
    case 'zh':
      return '//cdn.cookie-script.com/s/e0e97bd8beffbdaf2b89c6e200a686ef.js'
    case 'kr':
      return '//cdn.cookie-script.com/s/57c16d2782cb7ab193c391d312bf5a5d.js'

    default:
      return '//cdn.cookie-script.com/s/e0e97bd8beffbdaf2b89c6e200a686ef.js'
  }
}

export const getCalendlyLink = (lang: string | undefined) => {
  switch (lang) {
    case 'en':
      return 'https://calendly.com/polymerize/product-demo'
    case 'ja':
      return 'https://calendly.com/contact_jp-polymerize/30-45min'
    case 'zh':
      return 'https://calendly.com/heyin-hu/product-demo'

    default:
      return 'https://calendly.com/polymerize/product-demo?primary_color=ff8100&hide_gdpr_banner=1'
  }
}

export const getBlogUrl = (lang: string | undefined) => {
  switch (lang) {
    case 'en':
      return 'https://blog.polymerize.io'
    case 'ja':
      return 'https://blog.polymerize.jp'
    case 'zh':
      return 'https://blog.polymerize.tech'

    default:
      return 'https://blog.polymerize.io'
  }
}
